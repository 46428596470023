export default {
  about: {
    title: "Кто я?",
    name: "Меня зовут Артем и я уже три года являюсь Frontend-разработчиком.",
    shortInfo: "Программирование меня начало увлекать давно, свою первую работу в данном направлении я нашёл еще в 16 лет. Кстати, основа этого сайта, начиная от кода, заканчивая дизайном, была сделана тогда же.",
    currentInfo: "Сейчас я учусь на втором курсе в Высшей школе экономики. И, наверное, ищу работу, раз вы здесь находитесь 😉",
    endInfo: "Давайте переходить к следующему разделу!"
  },
  skills: {
    frontendDescription:
      "Frontend - моя основа. Технологии из данного списка я знаю лучше всего.",
    backendDescription:
      "Backend я знаю уже на менее хорошем уровне, но моих знаний достаточно для написания серверов средней сложности.",
    mobileDevelopment: "Мобильная разработка",
    software: "ПО",
  },
  projects: {
    title: "Мои проекты",
    description:
      "На этой странице вы сможете найти некоторые мои opensource/доступные для демонстрации проекты.",
    visit: "Посетить",
    github: "Посмотреть на github",
    thisSite: {
      title: "Этот сайт",
      description:
        "Сайт написан при помощи связки React (Gatsby) + TypeScript.",
    },
    localPass: {
      description:
        "LocalPass - программа для безопасного хранения паролей на компьютере при помощи шифрования. Написана на Electron + Vue.",
    },
    onenilla: {
      title: "Сайт OneNilla",
      description:
        "Сайт сервера по игре Minecraft. Написан при помощи React + TypeScript.",
    },
    urlShortener: {
      title: "Сервис коротких ссылок",
      description: "Укорачиватель ссылок на Nest.js. Пример: ",
    },
    muziqo: {
      title: "Muziqo",
      description: "Fullstack-проект. Сервис для прослушивания музыки, написанный на React, Typescript и Nest.js."
    }
  },
  contacts: {
    title: "Где меня можно найти?",
  },
  videos: {
    title: "Мои видео",
    error: "Произошла ошибка",
  },
  bottomLinks: {
    skills: "Мои навыки",
    projects: "Мои проекты",
    contact: "Где меня можно найти?",
    videos: "Мои видео",
  },
  meta: {
    description: "Сайт-портфолио Frontend разработчика Артёма Маргаряна.",
  },
};
